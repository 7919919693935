<template>
  <div>
    <van-nav-bar
      title="今日小练习"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="ninjia">
      <div class="ninjia-title">
        <div class="ctx-title">
          <div class="title-ctx">{{ task_name }}</div>
          <div class="title-fk"></div>
        </div>
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/sikao.png"
          alt=""
        />
      </div>
      <div class="jinritiyan">
        <div v-html="formattedText"></div>
        <img
          class="shurukuang"
          src="https://lesson.iapeap.com/images/EmotionalValue/shurukuang.png"
          alt=""
        />
        <textarea
          class="tiyanbianji"
          name=""
          id=""
          cols="30"
          rows="10"
          v-model="comment"
        ></textarea>

        <van-uploader v-model="fileList" multiple :after-read="afterRead">
          <div class="tianjia">+</div>
        </van-uploader>
        <!-- <van-uploader v-model="fileList" multiple /> -->
        <div class="ninjia-btn">
          <button @click="submit">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  uploadFile,
  saveEmoExercise,
  homePage,
  setComplated,
  memberLog,
} from "../../api/login";
import { Toast } from "vant";
export default {
  data() {
    return {
      fileList: [],
      imglist: [],
      comment: "",
      id: "",
      days: "",
      stage: "",
      task_info: "",
      task_name: "",
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  computed: {
    formattedText() {
      return this.task_info.replace(/\n/g, "<br>");
    },
  },

  mounted() {
    this.id = this.$route.query.item.id;
    this.task_info = this.$route.query.item.task_info;
    this.task_name = this.$route.query.item.task_name;

    console.log(this.$route.query.item);
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "今日小练习：" + this.task_name,
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
  created() {
    this.gethomePage();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    //获取当天阶段内容
    gethomePage() {
      let data = { userId: window.localStorage.getItem("userId") };
      homePage(data).then((res) => {
        this.days = res.days;
        this.stage = res.stage;
      });
    },
    //上传图片
    afterRead(file) {
      let data = file.file;
      uploadFile(data).then((res) => {
        // Toast.success("上传成功")
        //通过fileList为下标，把每一张上传的图片加到imglist数组里面
        this.imglist[this.fileList.length - 1] = res.imgUrl;
        console.log(this.imglist);
      });
    },

    //提交表单
    submit() {
      let comment2 = encodeURIComponent(this.comment);
      let data = {
        imgUrl: this.imglist,
        userId: window.localStorage.getItem("userId"),
        id: this.id,
        days: this.days,
        comment: comment2,
      };
      saveEmoExercise(data).then((res) => {
        this.onsetComplated();
      });
    },

    //点击完成
    onsetComplated() {
      let data2 = {
        id: this.id,
        userId: window.localStorage.getItem("userId"),
      };
      setComplated(data2).then((res) => {
        Toast.success("完成提交");
        setTimeout(() => {
          this.goBack();
        }, 1000);
      });
    },
  },
};
</script>

<style lang="less">
.ninjia {
  background-color: #f4fafe;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  .ninjia-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    img {
      width: 39px;
      height: 56px;
    }
  }
  .ninjia-btn {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    button {
      background-color: #629ee9;
      color: #fff;
      width: 200px;
      height: 36px;
      line-height: 36px;
      border: 0;
      border-radius: 50px;
    }
  }

  .jinritiyan {
    // height: 420px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
  }
  .tiyanbianji {
    width: 100%;
    height: 168px;
    border-radius: 8px;
    border: 2px solid #becfe3;
    padding: 15px 25px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .shurukuang {
    width: 15px;
    height: 15px;
    position: relative;
    top: 38px;
    left: 10px;
  }
  .tianjia {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    border: 1px solid #becfe3;
    line-height: 80px;
    text-align: center;
    color: #becfe3;
    font-size: 50px;
  }
  .ctx-title {
    .title-ctx {
      z-index: 999;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      color: #000025;
      line-height: 25px;
    }
    .title-fk {
      width: 35px;
      height: 5px;
      background: #629ee9;
      position: relative;
      bottom: 8px;
      z-index: 998;
      transform: skew(-25deg);
    }
  }
}
</style>
